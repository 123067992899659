import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  UK: {
    trading: {
      info: {
        title1: 'Last price',
        title2: '24h Change',
        title3: '24h High',
        title4: '24h Low',
        title5: '24h Volume'
      },
      assets: {
        name: 'Assets',
        sort1: 'Pair',
        sort2: 'Price',
        sort3: 'Vloume',
        sort4: 'Change',
        input: 'Search'
      },
      order: {
        title: 'Market',
        label1: 'Leverage',
        label2: 'Quantity',
        label3: 'Investment',
        text1: 'Funds Available:',
        text2: 'Profit',
        text3: 'Price',
        text4: 'Price change',
        text5: 'Loss',
        text6: 'Close the position if the profit reaches',
        text7: 'Close the position if the price reaches',
        text8: 'Close the position if the price rises by',
        text9: 'Total amount',
        text10: 'Trading session is closed now.',
        text11: 'The next session will open soon',
        btn1: 'MAX',
        btn2: 'Buy/Long',
        btn3: 'Sell/Short'
      },
      history: {
        title1: 'Open Orders',
        title2: 'Order History',
        info1: 'No open orders',
        info2: 'No historical orders',
        label1: 'Time',
        label2: 'Symbol',
        label3: 'Type',
        label4: 'Status',
        label5: 'Amount',
        label6: 'Entry',
        label7: 'Exit',
        label8: 'Profit',
        label9: 'Actions',
        type1: 'Buy',
        type2: 'Sell',
        status1: 'Opened',
        status2: 'Closed',
        btn: 'Close'
      }
    },
    interface: {
      header: {
        link1: 'Trading',
        link2: 'Fees',
        link3: 'KYC',
        link4: 'Wallet:',
        link5: 'Settings',
        link6: 'Log Out'
      },
      land: {
        title1: ['The world’s most', 'secure trading platform'],
        title2: 'For experienced traders',
        text1: ['Join the fastest growing global exchange with the', 'lowest fees around.'],
        text2: 'Trade like a pro on {name}',
        text3: 'Powerful',
        text4: 'Customize your private functionality and get more options',
        text5: 'Leverage',
        text6: 'Increase your personal income a few times faster',
        text7: 'Accurate',
        text8: 'Make sure your addresses are not involved in money laundering',
        text9: 'The complete trading essentials, unified in a single platform',
        text10: 'Integrated orders for spot and margin',
        text11: 'Set take profit, stop loss, limit orders, and more advanced order types for spot and margin in one unified order interface with up to 100x leverage.',
        text12: 'Deep liquidity and order book depth',
        text13: '{name} offers best-in-class liquidity and order book depth allowing large trades to be executed with the least slippage.',
        text14: 'Fully customizable workspaces',
        text15: 'Customize trading trading canvases with drag-and-drop modules such as multi-charts and trading data widgets.',
        text16: 'Zero-fee trading',
        text17: 'Unlock zero maker and taker spot trading fees by staking your assets in any invest plan.',
        text18: 'Trade futures with leverage',
        text19: 'Futures trading and leverage will be enabled across a variety of major pairs.',
        text20: 'Social trading',
        text21: 'Learn from profitable trading strategies and emulate successful traders with a record of proven profitability.',
        text22: 'Assets',
        text23: 'We offer a wide range of relevant assets on the market, which are curated with care by our experts.',
        text24: 'Daily market volume',
        text25: 'Our state-of-the-art systems are built to securely process high volumes each day.',
        text26: 'Best broker',
        text27: 'Trusted across Europe',
        text28: 'We have been voted "Best Broker" 2019 & 2020 and on our way to become the number 1 in Europe.',
        text29: 'Trade anytime, anywhere',
        text30: 'Boost your trading impact and reaction time in over 40+ assets via instant access to your portfolio.',
        btn1: 'Start trading',
        btn2: 'Login',
      },
      footer: {
        title1: 'Features',
        title2: 'Legal',
        title3: 'Contact',
        title4: 'Support',
        title5: 'Partners',
        title6: 'Marketing Support',
        text1: '{name} is a simple, elegant, and secure platform to build your portfolio.',
        text2: 'Worldwide Distributed Digital Asset Exchange | Trading digitals assets involves significant risks.',
        link1: 'Home',
        link2: 'Trading',
        link3: 'Wallet',
        link4: 'Terms of service',
        link5: 'Privacy notice',
        link6: 'Cookies policy',
        link7: 'AML & KYC policy',
      }
    },
    wallet: {
      title1: 'Welcome Back,',
      title2: 'Total balance',
      title3: 'Use bonus promo code',
      text1: 'Enter promo-code',
      text2: 'Deposit',
      text3: 'Withdraw',
      text4: 'History',
      text5: 'Personal wallet deposit',
      text6: 'Personal funds withdraw',
      text7: 'Wallet actions history',
      text8: 'Wallet Deposit Address',
      text9: 'Coins will be deposited after 3 network confirmations.',
      text10: 'Send only {sym} to this deposit address. Sending coin or token other than {sym} to this address may result in the loss of your deposit.',
      text11: 'Minimum deposit amount:',
      text12: 'Withdraw',
      text13: 'Destination {sym} address',
      text14: 'Please double check this address',
      text15: 'Please enter credit card number or USDT address',
      text16: 'Amount USD',
      text17: 'Maximum amount withdrawable:',
      text18: 'Important Information',
      text19: 'We strongly recommend that you copy & paste the address to help avoid errors. Please note that we are not responsible for coins mistakenly sent to the wrong address.',
      text20: 'Transactions normally take about 30 to 60 minutes to send, on occasion it can take a few hours if the crypto network is slow.',
      text21: 'Transaction History',
      text22: 'Time',
      text23: 'Amount',
      text24: 'Address',
      text25: 'Type',
      text26: 'Status',
      text27: 'Equivalent',
      text28: 'Withdraw',
      text29: 'Deposit',
      text30: 'Pending',
      text31: 'Bonus',
      text32: 'Completed',
      text33: 'Canceled',
      text34: 'Please enter amount',
      btn1: 'Activate',
      btn2: 'Deposit',
      btn3: 'Withdraw',
      btn4: 'Transactions',
      btn5: 'COPY',
      btn6: 'View deposit address',
      btn7: 'Withdraw now',
      link1: 'Open Settings'
    },
    settings: {
      title1: 'Personal Information',
      text1: 'Phone number',
      text2: 'Old Password',
      text3: 'New Password',
      text4: 'Your Name',
      text5: 'Full Name',
      text6: 'Current email',
      text7: 'Date of birth',
      text8: 'Present Address',
      text9: 'Permanent Address',
      text10: 'Postal Code',
      text11: 'Country',
      text12: 'City',
      btn1: 'Save',
      link1: 'Back to Wallet'
    },
    verify: {
      title1: 'Account Verification',
      title2: 'KYC verification',
      text1: 'To increase user limits, you need to pass the\nverification of a Intermediate user or a Advanced user,\nyou must provide personal information, a photo\nof an identity document, and a photo of your face.',
      text2: 'Account not verified',
      text3: 'Verification in progress',
      text4: 'Account verified',
      text5: 'First name',
      text6: 'Last name',
      text7: 'Select country',
      text8: 'Address',
      text9: 'Phone number',
      text10: 'Date of birth',
      text11: 'Select ID type',
      text12: 'Passport',
      text13: 'Driver license',
      text14: 'ID card',
      text15: 'ID number',
      text16: 'Enter ID number',
      text17: 'Upload the document',
      text18: 'Please provide a clear photo/scan of your document. Please make sure that the photo/scan is\ncomplete and clearly visible, in JPG/PNG format',
      text19: 'Choose file',
      text20: 'No file chosen',
      text21: 'Upload a selfie',
      text22: 'Please upload a photo of yourself that clearly shows your face.',
      text23: 'None',
      btn1: 'Verification page',
      btn2: 'Submit for review'
    },
    signin: {
      title1: 'Sign In',
      text1: 'Email',
      text2: 'Enter your email',
      text3: 'Password',
      text4: 'Enter your password',
      text5: 'Remember me',
      text6: 'New user?',
      btn1: 'Sign In',
      link1: 'Sign up',
      link2: 'Forgot password?'
    },
    signup: {
      title1: 'Sign up',
      title2: 'Last step of registration',
      text1: 'Username',
      text2: 'Enter your username',
      text3: 'Phone',
      text4: 'Enter your phone number',
      text5: 'Password',
      text6: 'Enter your password',
      text7: 'Confirm your password',
      text8: 'Enter your password',
      text9: 'I agree to the',
      text10: 'Terms & Conditions',
      text11: 'Already registered?',
      text12: 'To complete the verification process - enter the one-time code from the email\nbelow.',
      text13: 'To complete the verification process - verify your email.',
      text14: 'OTP Verify',
      text15: 'Email',
      text16: 'Enter your code',
      text17: 'Enter your email',
      text18: 'Resend in {timer} seconds',
      text19: 'Resend code',
      btn1: 'Sign up',
      btn2: 'Create',
      btn3: 'Cancel',
      link1: 'Sign in'
    },
    reset: {
      title1: 'Reset your password',
      text1: 'Email',
      text2: 'Enter your email',
      text3: 'Remember your password?',
      btn1: 'Send new password',
      link1: 'Sign in'
    }
  },
  RU: {
    trading: {
      info: {
        title1: 'Последняя цена',
        title2: '24ч Изменение',
        title3: '24ч Макс',
        title4: '24ч Мин',
        title5: '24ч Объем'
      },
      assets: {
        name: 'Активы',
        sort1: 'Активы',
        sort2: 'Цена',
        sort3: 'Объем',
        sort4: 'Изменение',
        input: 'Поиск'
      },
      order: {
        title: 'Маркет',
        label1: 'Кредитное плечо',
        label2: 'Количество',
        label3: 'Инвестиции',
        text1: 'Доступно:',
        text2: 'Прибыль',
        text3: 'Цена',
        text4: 'Изменение',
        text5: 'Убыток',
        text6: 'Закрыть позицию, если прибыль достигнет',
        text7: 'Закрыть позицию, если цена достигнет',
        text8: 'Закрыть позицию, если цена вырастет на',
        text9: 'Общая сумма',
        text10: 'Торговая сессия закрыта.',
        text11: 'Следующая сессия скоро откроется',
        btn1: 'МАКС',
        btn2: 'Купить/Лонг',
        btn3: 'Продать/Шорт'
      },
      history: {
        title1: 'Открытые ордера',
        title2: 'История ордеров',
        info1: 'Нет открытых ордеров',
        info2: 'Нет истории ордеров',
        label1: 'Время',
        label2: 'Активы',
        label3: 'Тип',
        label4: 'Статус',
        label5: 'Количество',
        label6: 'Открытие',
        label7: 'Закрытие',
        label8: 'Прибыль',
        label9: 'Действие',
        type1: 'Лонг',
        type2: 'Шорт',
        status1: 'Открыт',
        status2: 'Закрыт',
        btn: 'Закрыть'
      }
    },
    interface: {
      header: {
        link1: 'Торговля',
        link2: 'Комиссии',
        link3: 'KYC',
        link4: 'Кошелек:',
        link5: 'Настройки',
        link6: 'Выход'
      },
      land: {
        title1: ['Самая безопасная', 'торговая платформа в мире'],
        title2: 'Для опытных трейдеров',
        text1: ['Присоединяйтесь к самой быстрорастущей мировой бирже с', 'самыми низкими комиссиями.'],
        text2: 'Торгуйте как профессионал на {name}',
        text3: 'Мощный',
        text4: 'Настройте свою личную функциональность и получите больше возможностей',
        text5: 'Кредитное плечо',
        text6: 'Увеличьте свой личный доход в несколько раз быстрее',
        text7: 'Безопасный',
        text8: 'Убедитесь, что ваши адреса не связаны с отмыванием денег',
        text9: 'Все необходимое для трейдинга, объединенное на одной платформе',
        text10: 'Интегрированные ордера на спот и маржу',
        text11: 'Установите тейк-профит, стоп-лосс, лимитные ордера и более сложные типы ордеров для спот и маржи в едином интерфейсе ордеров с кредитным плечом до 100x.',
        text12: 'Глубокая ликвидность и глубина книги ордеров',
        text13: '{name} предлагает лучшую в своем классе ликвидность и глубину книги ордеров, что позволяет совершать крупные сделки с наименьшим проскальзыванием.',
        text14: 'Полностью настраиваемые рабочие пространства',
        text15: 'Настройте торговые элементы с помощью модулей перетаскивания, таких как мультиграфики и виджеты торговых данных.',
        text16: 'Торговля с нулевой комиссией',
        text17: 'Разблокируйте нулевую комиссию за спотовую торговлю мейкера и тейкера, разместив свои активы в любом инвестиционном плане.',
        text18: 'Торговля фьючерсами с кредитным плечом',
        text19: 'Торговля фьючерсами и кредитное плечо будут доступны для различных основных пар.',
        text20: 'Социальная торговля',
        text21: 'Учитесь на прибыльных торговых стратегиях и подражайте успешным трейдерам с подтвержденной прибыльностью.',
        text22: 'Активы',
        text23: 'Мы предлагаем широкий спектр актуальных активов на рынке, которые тщательно отбираются нашими экспертами.',
        text24: 'Ежедневный объем рынка',
        text25: 'Наши современные системы созданы для безопасной обработки больших объемов данных каждый день.',
        text26: 'Топ 1',
        text27: 'Доверие по всей Европе',
        text28: 'Мы были признаны "Лучшим брокером" 2019 и 2020 годов и находимся на пути к тому, чтобы стать номером 1 в Европе.',
        text29: 'Торгуйте в любое время и в любом месте',
        text30: 'Повысьте свое влияние на торговлю и время реакции на более чем 40 активов благодаря мгновенному доступу к вашему портфелю.',
        btn1: 'Начать торговлю',
        btn2: 'Авторизоваться',
      },
      footer: {
        title1: 'Функции',
        title2: 'Юридическое',
        title3: 'Связь',
        title4: 'Поддержка',
        title5: 'Партнеры',
        title6: 'Маркетинговая поддержка',
        text1: '{name} это простая, элегантная и безопасная платформа для создания вашего портфолио.',
        text2: 'Всемирная биржа цифровых активов | Торговля цифровыми активами сопряжена со значительными рисками.',
        link1: 'Главная',
        link2: 'Торговля',
        link3: 'Кошелек',
        link4: 'Условия использования',
        link5: 'Условия конфиденциальности',
        link6: 'Политика файлов cookie',
        link7: 'Политика AML и KYC',
      }
    },
    wallet: {
      title1: 'Добро пожаловать,',
      title2: 'Общий баланс',
      title3: 'Используйте бонусный промокод',
      text1: 'Введите промо-код',
      text2: 'Депозит',
      text3: 'Вывод',
      text4: 'История',
      text5: 'Пополнение личного кошелька',
      text6: 'Вывод личных средств',
      text7: 'История действий с кошельком',
      text8: 'Адрес кошелька для депозита',
      text9: 'Транзакция будет зачислена после 3 подтверждений сети.',
      text10: 'Отправляйте только {sym} на этот депозитный адрес. Отправка монет или токенов, отличных от {sym} может привести к потере вашего депозита',
      text11: 'Минимальная сумма депозита:',
      text12: 'Вывод',
      text13: '{sym} адрес',
      text14: 'Пожалуйста, перепроверьте этот адрес',
      text15: 'Пожалуйста, введите номер карты или USDT адрес',
      text16: 'Сумма USD',
      text17: 'Максимальная сумма вывода:',
      text34: 'Пожалуйста, введите сумму',
      text18: 'Важная информация',
      text19: 'Мы настоятельно рекомендуем вам скопировать и вставить адрес, чтобы избежать ошибок. Обратите внимание, что мы не несем ответственности за монеты, отправленные по ошибке на неверный адрес.',
      text20: 'Отправка транзакций обычно занимает от 30 до 60 минут, иногда это может занять несколько часов, в зависимости от загруженности криптосети.',
      text21: 'История транзакций',
      text22: 'Время',
      text23: 'Количество',
      text24: 'Адрес',
      text25: 'Тип',
      text26: 'Статус',
      text27: 'Эквивалент',
      text28: 'Вывод',
      text29: 'Депозит',
      text30: 'Ожидание',
      text31: 'Бонус',
      text32: 'Завершено',
      text33: 'Отменено',
      text35: 'Нет транзакций',
      btn1: 'Активировать',
      btn2: 'Депозит',
      btn3: 'Вывод',
      btn4: 'Транзакции',
      btn5: 'СКОПИР.',
      btn6: 'Показать адрес',
      btn7: 'Вывести',
      link1: 'Настройки'
    },
    settings: {
      title1: 'Персональная информация',
      text1: 'Номер телефона',
      text2: 'Старый пароль',
      text3: 'Новый пароль',
      text4: 'Имя',
      text5: 'Полное имя',
      text6: 'Текущая электронная почта',
      text7: 'Дата рождения',
      text8: 'Текущий адрес',
      text9: 'Постоянный адрес',
      text10: 'Почтовый индекс',
      text11: 'Страна',
      text12: 'Город',
      btn1: 'Сохранить',
      link1: 'Назад в кошелек'
    },
    verify: {
      title1: 'Верификация учетной записи',
      title2: 'KYC верификация',
      text1: 'Для увеличения лимитов необходимо пройти\nверификацию пользователя, предоставить\nличную информацию, фотографию\nдокумента, удостоверяющего личность, и фотографию своего лица.',
      text2: 'Аккаунт не подтвержден',
      text3: 'Выполняется проверка',
      text4: 'Аккаунт подтвержден',
      text5: 'Имя',
      text6: 'Фамилия',
      text7: 'Выберите страну',
      text8: 'Адрес',
      text9: 'Номер телефона',
      text10: 'Дата рождения',
      text11: 'Выберите тип документа',
      text12: 'Паспорт',
      text13: 'Водительские права',
      text14: 'ID карта',
      text15: 'Номер документа',
      text16: 'Введите номер документа',
      text17: 'Загрузить документ',
      text18: 'Пожалуйста, предоставьте четкое фото/скан вашего документа. Пожалуйста, убедитесь, что фото\nотсканировано и хорошо видно, в формате JPG/PNG.',
      text19: 'Выберите файл',
      text20: 'Файл не выбран',
      text21: 'Загрузить селфи',
      text22: 'Пожалуйста, загрузите свою фотографию, на которой четко видно ваше лицо.',
      text23: 'Нет',
      btn1: 'Перейти к верификации',
      btn2: 'Отправить'
    },
    signin: {
      title1: 'Авторизация',
      text1: 'Электронная почта',
      text2: 'Введите электронную почту',
      text3: 'Пароль',
      text4: 'Введите ваш пароль',
      text5: 'Запомнить меня',
      text6: 'Новый пользователь?',
      btn1: 'Войти',
      link1: 'Зарегистрироваться',
      link2: 'Забыли пароль?'
    },
    signup: {
      title1: 'Регистрация',
      title2: 'Последний шаг регистрации',
      text1: 'Имя пользователя',
      text2: 'Введите имя пользователя',
      text3: 'Номер телефона',
      text4: 'Введите свой номер телефона',
      text5: 'Пароль',
      text6: 'Введите ваш пароль',
      text7: 'Подтвердите ваш пароль',
      text8: 'Введите ваш пароль',
      text9: 'Я согласен с',
      text10: 'Правилами и Условиями',
      text11: 'Уже зарегистрированы?',
      text12: 'Чтобы завершить процесс проверки, введите одноразовый код из письма\nниже.',
      text13: 'Для завершения процесса проверки - подтвердите свой адрес электронной почты.',
      text14: 'OTP Проверка',
      text15: 'Электронная почта',
      text16: 'Введите ваш код',
      text17: 'Введите адрес электронной почты',
      text18: 'Отправить повторно через {timer} сек.',
      text19: 'Отправить еще раз',
      btn1: 'Зарегистрироваться',
      btn2: 'Отправить',
      btn3: 'Отмена',
      link1: 'Авторизация'
    },
    reset: {
      title1: 'Сбросить пароль',
      text1: 'Электронная почта',
      text2: 'Введите электронную почту',
      text3: 'Помните свой пароль?',
      btn1: 'Отправить новый пароль',
      link1: 'Авторизоваться'
    }
  },
  DE: {
    trading: {
      info: {
        title1: 'Letzter Preis',
        title2: '24h Wechsel',
        title3: '24h Hoch',
        title4: '24h Niedrig',
        title5: '24h Volumen'
      },
      assets: {
        name: 'Aktiva',
        sort1: 'Paar',
        sort2: 'Preis',
        sort3: 'Volumen',
        sort4: 'Ändern',
        input: 'Suche'
      },
      order: {
        title: 'Markt',
        label1: 'Hebelkraft',
        label2: 'Menge',
        label3: 'Investition',
        text1: 'Verfügbare Mittel:',
        text2: 'Profitieren',
        text3: 'Preis',
        text4: 'Preisänderung',
        text5: 'Verlust',
        text6: 'Schließen Sie die Position, wenn der Gewinn erreicht ist',
        text7: 'Schließen Sie die Position, wenn der Preis erreicht ist',
        text8: 'Schließen Sie die Position, wenn der Preis um steigt',
        text9: 'Gesamtmenge',
        text10: 'Die Handelssitzung ist jetzt geschlossen.',
        text11: 'Die nächste Sitzung wird bald eröffnet',
        btn1: 'MAX',
        btn2: 'Kaufen/Long',
        btn3: 'Verkaufen/Short'
      },
      history: {
        title1: 'Offene Aufträge',
        title2: 'Aufträge Historie',
        info1: 'Keine offenen aufträge',
        info2: 'Keine historische aufträge',
        label1: 'Zeit',
        label2: 'Symbol',
        label3: 'Typ',
        label4: 'Status',
        label5: 'Menge',
        label6: 'Eintrag',
        label7: 'Ausfahrt',
        label8: 'Profitieren',
        label9: 'Aktionen',
        type1: 'Kaufen',
        type2: 'Verkaufen',
        status1: 'Geöffnet',
        status2: 'Geschlossen',
        btn: 'Schließen'
      }
    },
    interface: {
      header: {
        link1: 'Handel',
        link2: 'Gebühren',
        link3: 'KYC',
        link4: 'Beutel:',
        link5: 'Rahmen',
        link6: 'Ausloggen'
      },
      land: {
        title1: ['Die meisten der Welt', 'sichere Handelsplattform'],
        title2: 'Für erfahrene Händler',
        text1: ['Treten Sie der am schnellsten wachsenden globalen Börse mit bei', 'niedrigste Gebühren überhaupt.'],
        text2: 'Handeln Sie wie ein Profi auf {name}',
        text3: 'Kraftvoll',
        text4: 'Passen Sie Ihre privaten Funktionen an und erhalten Sie mehr Optionen',
        text5: 'Hebelwirkung',
        text6: 'Steigern Sie Ihr persönliches Einkommen um ein Vielfaches schneller',
        text7: 'Präzise',
        text8: 'Stellen Sie sicher, dass Ihre Adressen nicht in Geldwäsche verwickelt sind',
        text9: 'Die kompletten Handelsgrundlagen, vereint auf einer einzigen Plattform',
        text10: 'Integrierte Orders für Spot und Margin',
        text11: 'Legen Sie Take-Profit-, Stop-Loss-, Limit-Orders und erweiterte Ordertypen für Spot- und Margin-Orders in einer einheitlichen Orderschnittstelle mit bis zu 100-facher Hebelwirkung fest.',
        text12: 'Hohe Liquidität und Auftragsbuchtiefe',
        text13: '{name} bietet erstklassige Liquidität und Orderbuchtiefe, sodass große Geschäfte mit dem geringsten Slippage ausgeführt werden können.',
        text14: 'Vollständig anpassbare Arbeitsbereiche',
        text15: 'Passen Sie Trading-Canvas mit Drag-and-Drop-Modulen wie Multi-Charts und Handelsdaten-Widgets an.',
        text16: 'Gebührenfreier Handel',
        text17: 'Profitieren Sie von den Null-Maker- und Taker-Spothandelsgebühren, indem Sie Ihr Vermögen in einen beliebigen Anlageplan investieren.',
        text18: 'Handeln Sie Futures mit Hebelwirkung',
        text19: 'Der Futures-Handel und die Hebelwirkung werden für eine Vielzahl wichtiger Währungspaare ermöglicht.',
        text20: 'Sozialer Handel',
        text21: 'Lernen Sie von profitablen Handelsstrategien und ahmen Sie erfolgreiche Händler mit einer nachgewiesenen Rentabilität nach.',
        text22: 'Vermögenswerte',
        text23: 'Wir bieten eine breite Palette relevanter Assets auf dem Markt an, die von unseren Experten sorgfältig kuratiert werden.',
        text24: 'Tägliches Marktvolumen',
        text25: 'Unsere hochmodernen Systeme sind darauf ausgelegt, jeden Tag große Mengen sicher zu verarbeiten.',
        text26: 'Bester Makler',
        text27: 'Vertrauen in ganz Europa',
        text28: 'Wir wurden zum "Besten Broker" 2019 & 2020 gewählt und sind auf dem Weg, die Nummer 1 in Europa zu werden.',
        text29: 'Handeln Sie jederzeit und überall',
        text30: 'Steigern Sie Ihre Handelswirkung und Reaktionszeit bei über 40 Vermögenswerten durch sofortigen Zugriff auf Ihr Portfolio.',
        btn1: 'Mit dem handel beginnen',
        btn2: 'Login',
      },
      footer: {
        title1: 'Merkmale',
        title2: 'Legal',
        title3: 'Kontakt',
        title4: 'Stützen',
        title5: 'Partner',
        title6: 'Marketingunterstützung',
        text1: '{name} ist eine einfache, elegante und sichere Plattform zum Aufbau Ihres Portfolios.',
        text2: 'Weltweit verteilter Austausch digitaler Vermögenswerte | Der Handel mit digitalen Vermögenswerten birgt erhebliche Risiken.',
        link1: 'Heim',
        link2: 'Handel',
        link3: 'Geldbörse',
        link4: 'Nutzungsbedingungen',
        link5: 'Datenschutzerklärung',
        link6: 'Cookie-Richtlinie',
        link7: 'AML und KYC Richtlinie',
      }
    },
    wallet: {
      title1: 'Willkommen zurück,',
      title2: 'Gesamtsaldo',
      title3: 'Bonus-Promocode',
      text1: 'Geben Sie den code',
      text2: 'Kaution',
      text3: 'Zurückziehen',
      text4: 'Historie',
      text5: 'Persönliche Geldbörseneinzahlung',
      text6: 'Persönliche Gelder werden abgehoben',
      text7: 'Verlauf der Wallet-Aktionen',
      text8: 'Wallet-Einzahlungsadresse',
      text9: 'Münzen werden nach 3 Netzwerkbestätigungen eingezahlt.',
      text10: 'Senden Sie nur {sym} an diese Einzahlungsadresse. Das Senden einer anderen Münze oder eines anderen Tokens als {sym} an diese Adresse kann zum Verlust Ihrer Einzahlung führen.',
      text11: 'Mindesteinzahlungsbetrag:',
      text12: 'Zurückziehen',
      text13: 'Zieladresse {sym}',
      text14: 'Bitte überprüfen Sie diese Adresse noch einmal',
      text15: 'Bitte geben Sie die {sym} adresse des Empfängers ein',
      text16: 'Menge USD',
      text17: 'Maximal auszahlbarer Betrag:',
      text18: 'Wichtige Informationen',
      text19: 'Wir empfehlen Ihnen dringend, die Adresse zu kopieren und einzufügen, um Fehler zu vermeiden. Bitte beachten Sie, dass wir nicht für Münzen verantwortlich sind, die fälschlicherweise an die falsche Adresse gesendet werden.',
      text20: 'Der Versand von Transaktionen dauert normalerweise etwa 30 bis 60 Minuten. Gelegentlich kann es jedoch auch einige Stunden dauern, wenn das Krypto-Netzwerk langsam ist',
      text21: 'Verlauf der Transaktionen',
      text22: 'Zeit',
      text23: 'Menge',
      text24: 'Adresse',
      text25: 'Typ',
      text26: 'Status',
      text27: 'Äquivalent',
      text28: 'Zurückziehen',
      text29: 'Kaution',
      text30: 'Ausstehend',
      text31: 'Bonus',
      text32: 'Vollendet',
      text33: 'Abgesagt',
      btn1: 'Aktivieren',
      btn2: 'Kaution',
      btn3: 'Zurückziehen',
      btn4: 'Transaktionen',
      btn5: 'KOPIE',
      btn6: 'Einzahlungsadresse anzeigen',
      btn7: 'Jetzt zurückziehen',
      link1: 'Rahmen'
    },
    settings: {
      title1: 'Persönliche Angaben',
      text1: 'Telefonnummer',
      text2: 'Altes Passwort',
      text3: 'Neues Kennwort',
      text4: 'Ihren Namen',
      text5: 'Vollständiger Name',
      text6: 'Aktuelle E-Mail',
      text7: 'Geburtsdatum',
      text8: 'Aktuelle Adresse',
      text9: 'Fester Wohnsitz',
      text10: 'Postleitzahl',
      text11: 'Land',
      text12: 'Stadt',
      btn1: 'Speichern',
      link1: 'Zurück zur Brieftasche'
    },
    verify: {
      title1: 'Bestätigung des Kontos',
      title2: 'KYC verifikation',
      text1: 'Um die Benutzerlimits zu erhöhen, müssen Sie die\nVerifizierung eines fortgeschrittenen Benutzers oder eines fortgeschrittenen Benutzers bestehen.\nSie müssen persönliche Informationen, ein Foto\neines Ausweisdokuments und ein Foto Ihres Gesichts angeben.',
      text2: 'Konto nicht verifiziert',
      text3: 'Überprüfung läuft',
      text4: 'Konto verifiziert',
      text5: 'Vorname',
      text6: 'Familienname',
      text7: 'Land auswählen',
      text8: 'Adresse',
      text9: 'Telefonnummer',
      text10: 'Geburtsdatum',
      text11: 'ID-Typ',
      text12: 'Reisepass',
      text13: 'Führerschein',
      text14: 'ID karte',
      text15: 'ID nummer',
      text16: 'Eingeben ID nummer',
      text17: 'Laden Sie das Dokument hoch',
      text18: 'Bitte legen Sie ein klares Foto/Scan Ihres Dokuments bei. Bitte stellen Sie sicher, dass das Foto/der Scan\nvollständig und deutlich sichtbar ist und im JPG/PNG-Format vorliegt',
      text19: 'Datei wählen',
      text20: 'Keine Datei ausgewählt',
      text21: 'Laden Sie ein Selfie hoch',
      text22: 'Bitte laden Sie ein Foto von sich hoch, auf dem Ihr Gesicht deutlich zu sehen ist.',
      text23: 'None',
      btn1: 'Verifizierungsseite',
      btn2: 'Einreichen'
    },
    signin: {
      title1: 'Eintragen',
      text1: 'Email',
      text2: 'E-Mail Adresse',
      text3: 'Passwort',
      text4: 'Geben Sie Ihr Passwort ein',
      text5: 'Mich erinnern',
      text6: 'Neuer Benutzer?',
      btn1: 'Eintragen',
      link1: 'Unterschreiben',
      link2: 'Passwort vergessen?'
    },
    signup: {
      title1: 'Unterschreiben',
      title2: 'Letzter Schritt der Registrierung',
      text1: 'Nutzername',
      text2: 'Nutzername',
      text3: 'Telefon',
      text4: 'Telefonnummer',
      text5: 'Passwort',
      text6: 'Passwort',
      text7: 'Bestätigen Sie Ihr Passwort',
      text8: 'Passwort',
      text9: 'Ich stimme dem zu',
      text10: 'Terms & Conditions',
      text11: 'Bereits registriert?',
      text12: 'Um den Verifizierungsprozess abzuschließen, geben Sie den Einmalcode aus der E-Mail ein.\nunten.',
      text13: 'Um den Verifizierungsprozess abzuschließen, bestätigen Sie Ihre E-Mail-Adresse.',
      text14: 'OTP Verifizieren',
      text15: 'E-Mail',
      text16: 'Gib deinen Code ein',
      text17: 'Geben sie ihre E-Mail Adresse ein',
      text18: 'In {timer} Sekunden erneut senden',
      text19: 'Code erneut senden',
      btn1: 'Unterschreiben',
      btn2: 'Senden',
      btn3: 'Stornieren',
      link1: 'Anmelden'
    },
    reset: {
      title1: 'Setze dein Passwort zurück',
      text1: 'Email',
      text2: 'E-Mail Adresse',
      text3: 'Merken Sie sich Ihr Passwort?',
      btn1: 'Neues Passwort senden',
      link1: 'Eintragen'
    }
  },
}

const lang = localStorage.getItem('lang')

export default new VueI18n({
  locale: lang ? lang : 'UK',
  messages
})
